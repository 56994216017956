import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";

const ThankYou = () => {
  return (
    <>
      <PageTitle title="धन्यवाद" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="success-container text-center">
                <h4>धन्यवाद</h4>
                <Link to="/my-profile" className="btn btn-danger">तुमचा प्रोफाइल इथे पाहा</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ThankYou;
