import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import PageTitle from "../../components/PageTitle/PageTitle";
import ProfileService from "../../services/profile.service";

export const Export = ({ onExport }) => (
  <button onClick={() => onExport()}>Export</button>
);

const GroomsExcel = () => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    ProfileService.getGrooms()
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const columns = [
    {
      name: "पूर्ण नाव",
      selector: (row) => (
        <Link to={`user-profile/${row.id}`}>
          {row.last_name} {row.title}{row.first_name} {row.middle_name}
        </Link>
      ),
      cellExport: (row) => (
        row.last_name + ' ' + row.title + ' ' + row.first_name + ' ' + row.middle_name
      ),
      width: "230px"
    },
    {
      name: "First Name",
      selector: (row) => (row.first_name),
      cellExport: (row) => (row.first_name),
      width: "230px"
    },
    {
      name: "Middle Name",
      selector: (row) => (row.middle_name),
      cellExport: (row) => (row.middle_name),
      width: "230px"
    },
    {
      name: "Last Name",
      selector: (row) => (row.last_name),
      cellExport: (row) => (row.last_name),
      width: "230px"
    },
    {
      name: "Photo ID",
      selector: (row) => (row.profile_number),
      cellExport: (row) => (row.profile_number),
      width: "230px"
    },
    {
      name: "Gender",
      selector: (row) => (row.gender),
      cellExport: (row) => (row.gender),
      width: "230px"
    },
    {
      name: "Mobile",
      selector: (row) => (row.mobile),
      cellExport: (row) => (row.mobile),
      width: "230px"
    },
    {
      name: "City",
      selector: (row) => (row.post_district),
      cellExport: (row) => (row.post_district),
      width: "230px"
    },
    {
      name: "Pincode",
      selector: (row) => (row.post_pincode),
      cellExport: (row) => (row.post_pincode),
      width: "230px"
    },
  ];

  const tableData = {
    columns,
    data: userList,
  };

  return (
    <>
      <PageTitle title="Grooms Excel" />
      <section className="vh_dashboard">
        <Container className="mt-3">
          <Row>
            <Col md={12}>
              <div className="vh_card">
                <h2 className="section-title" style={{'fontSize':'1.5rem'}}>सर्व नोंदणी</h2>
                <DataTableExtensions
                  {...tableData}
                  >
                  <DataTable 
                    // columns={columns} 
                    // data={userList} 
                    striped={true} 
                    highlightOnHover={true} 
                    pagination={true} 
                    paginationPerPage={50} 
                    paginationRowsPerPageOptions={[50, 100, 150, 200, 250]} />
                </DataTableExtensions>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GroomsExcel;
