import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { MenuList } from "./MenuList";
import { useDispatch, useSelector } from "react-redux";
import { LoggedInMenuList } from './LoggedInMenuList';
import { userLogout } from "../../redux/reducers/user/user.thunk";
import jwtDecode from 'jwt-decode';

const NavigationBar = (props) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const clickHandler = () => {
    setClicked(!clicked);
  };

  const user = useSelector(state => state.user)
    const [isAuthenticated, setIsAuthenticated] = useState(null)  
    useEffect(() => {
      let token = localStorage.getItem('user-token');
          if(token){
              let tokenExpiration = jwtDecode(token).exp;
              let dateNow = new Date();
              if(tokenExpiration < dateNow.getTime()/1000){
                  setIsAuthenticated(false)
              }else{
                  setIsAuthenticated(true)
              }
          } else {
             setIsAuthenticated(false)
          }
      // eslint-disable-next-line
    }, [user]);

    let menuList = "";

    if(isAuthenticated != null) {
      if(isAuthenticated) {
        menuList = LoggedInMenuList.map(({ url, title }, index) => {
          return (
            <li key={index} onClick={clickHandler}>
              <Nav.Link as={NavLink} exact to={url} activeClassName="active">
                {title}
              </Nav.Link>
            </li>
          );
        });
      } else {
        menuList = MenuList.map(({ url, title }, index) => {
          return (
            <li key={index} onClick={clickHandler}>
              <Nav.Link as={NavLink} exact to={url} activeClassName="active">
                {title}
              </Nav.Link>
            </li>
          );
        });
      }
    } else {
      menuList = MenuList.map(({ url, title }, index) => {
        return (
          <li key={index} onClick={clickHandler}>
            <Nav.Link as={NavLink} exact to={url} activeClassName="active">
              {title}
            </Nav.Link>
          </li>
        );
      });
    }

  const handleClick = () => {
    dispatch(userLogout());
    if(props.history != undefined) {
      props.history.push("/");
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Admin Panel
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
          <Nav>
            {menuList}
            { isAuthenticated != null && isAuthenticated ? <li><Button className="nav-link" onClick={handleClick}>लॉग आऊट</Button></li> : '' }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
