import moment from "moment";

export const convertToDevanagariDate = (dateString) => {
    let dateParts = dateString.split("-");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

    let day = convertToDevanagari(dateParts[2]);
    let month = convertToDevanagari(dateParts[1]);
    let year = convertToDevanagari(dateParts[0]);

    let devanagariDate = day + '/' + month + '/' + year;

    return devanagariDate;
}

export const convertToDevanagariTime = (timeString) => {
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    timeString = h + timeString.substr(2, 3);

    let timeParts = timeString.split(":");
    let hrs = convertToDevanagari(timeParts[0]);
    let mins = convertToDevanagari(timeParts[1]);

    let devanagariTime = hrs + '.' + mins;
    return devanagariTime;
}

export const convertToDevanagari = (number) => {
    let englishDigits = {
        '०': '0',
        '१': '1',
        '२': '2',
        '३': '3',
        '४': '4',
        '५': '5',
        '६': '6',
        '७': '7',
        '८': '8',
        '९': '9'
    };
    let devanagariDigits = {
        '0': '०',
        '1': '१',
        '2': '२',
        '3': '३',
        '4': '४',
        '5': '५',
        '6': '६',
        '7': '७',
        '8': '८',
        '9': '९'
    };

    if(number != undefined) {
        let converted_number = number.toString().replace(/[0123456789]/g, function (s) {
            return devanagariDigits[s];
        });
        return converted_number;
    }
    
    return number;
    
}

export const convertToEnglish = (number) => {
    let englishDigits = {
        '०': '0',
        '१': '1',
        '२': '2',
        '३': '3',
        '४': '4',
        '५': '5',
        '६': '6',
        '७': '7',
        '८': '8',
        '९': '9'
    };
    let devanagariDigits = {
        '0': '०',
        '1': '१',
        '2': '२',
        '3': '३',
        '4': '४',
        '5': '५',
        '6': '६',
        '7': '७',
        '8': '८',
        '9': '९'
    };

    if(number != undefined) {
        let converted_number = number.toString().replace(/[०१२३४५६७८९]/g, function (s) {
            return englishDigits[s];
        });
        return converted_number;
    }
    
    return number;
    
}

export const convertDate = (string) => {
    let date = moment.utc(string).local().format('YYYY-MM-DD');
    let devnagariDate = convertToDevanagariDate(date);
    return devnagariDate;
}