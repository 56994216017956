import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { convertToDevanagari, convertToDevanagariDate, convertToDevanagariTime } from "../helpers/utility";
import Urls from "../constants/urls";

// Register font
Font.register({
  family: "Noto Sans",
  src: `${Urls.APP_URL}/assets/fonts/notosans-regular.ttf`,
});
Font.register({
  family: "Noto Sans Bold",
  src: `${Urls.APP_URL}/assets/fonts/notosans-regular.ttf`,
});

// PDF styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
    // height: "793.7pt",
    fontFamily: "Noto Sans",
    // backgroundColor: "#d7d7d7",
    paddingLeft: "45.35pt",
    paddingTop: "28.35pt",
    // paddingBottom: "28.35pt",
    paddingRight: "36.85pt",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "2pt solid #000000",
    width: "518.74pt",
    minHeight: "19.84pt",
    fontFamily: "Noto Sans Bold",
  },
  eventNameContainer: {
    flex: "6",
  },
  eventName: {
    fontSize: "12.5pt",
    paddingLeft: "5pt",
  },
  sideTextContainer: {
    backgroundColor: "#000000",
    flex: "1",
    borderTopLeftRadius: "10pt",
    borderTopRightRadius: "10pt",
    borderBottomRightRadius: "10pt",
    borderBottomLeftRadius: "10pt",
  },
  sideText: {
    color: "#ffffff",
    fontSize: "14pt",
    textAlign: "center",
    marginTop: "-2pt",
  },
  pageNoContainer: {
    // backgroundColor: 'red',
    width: "520.6pt",
    height: "15pt",
    minHeight: "15pt",
    position: "absolute",
    bottom: "15pt",
    left: "45.35pt",
    zIndex: "9999",
  },
  pageNo: {
    textAlign: "center",
    color: "#000000",
    fontSize: "10pt",
    minHeight: "15pt",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // backgroundColor: "#e7e7e7",
    marginTop: "3pt",
    paddingTop: "2pt",
    paddingLeft: "2pt",
    // minHeight: "714.33pt",
    // border: "1pt solid #000000",
  },
  infoCard: {
    position: "relative",
    width: "260.3pt",
    height: "141.73pt",
    minHeight: "141.73pt",
    border: "2pt solid #000000",
    padding: "1pt",
    marginTop: "-2pt",
    marginLeft: "-2pt",
  },
  noCircle: {
    backgroundColor: "#000000",
    color: "#ffffff",
    fontSize: "10pt",
    // fontFamily: "Vesper Libre",
    textAlign: "center",
    position: "absolute",
    right: "2pt",
    top: "2pt",
    width: "28pt",
    height: "15pt",
    zIndex: "999",
    borderTopLeftRadius: "100%",
    borderTopRightRadius: "100%",
    borderBottomRightRadius: "100%",
    borderBottomLeftRadius: "100%",
  },
  noCircleText: {
    marginTop: "-0.5pt",
    fontFamily: "Noto Sans Bold",
  },
  vhFlex: {
    position: "relative",
    zIndex: "99",
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "#fcdccc",
    fontSize: "8pt",
  },
  vhTitle: {
    width: "48.19pt",
    minWidth: "48.19pt",
    paddingLeft: "2pt",
  },
  vhColon: {
    width: "5pt",
  },
  vhValue: {
    width: "207.11pt",
    paddingRight: "2pt",
  },
  vhFlexName: {
    position: "relative",
    zIndex: "99",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: "#fcdccc",
    fontSize: "8pt",
  },
  vhTitleName: {
    width: "48.19pt",
    minWidth: "48.19pt",
    paddingLeft: "2pt",
    fontSize: "9.5pt",
    fontFamily: "Noto Sans Bold",
  },
  vhValueName: {
    width: "207.11pt",
    paddingRight: "2pt",
    fontSize: "11pt",
    fontFamily: "Noto Sans Bold",
  },
});

const BrideTextPdf = (props) => {
  return (
    <Document>
      {props.data
        ? props.data.map((users, index) => {
            return (
              <Page style={styles.page} size={[600.94, 793.7]} key={index}>
                {/* Page title */}
                <View style={styles.pageTitle} fixed>
                  <View style={styles.eventNameContainer}>
                    <Text style={styles.eventName}>
                      वीरशैव हितसंवर्धक मंडळ, यवतमाळ लिंगायत समाज उपवधू-उपवर
                      परिचय मेळावा - २०२४
                    </Text>
                  </View>
                  <View style={styles.sideTextContainer}>
                    <Text style={styles.sideText}>उपवधू</Text>
                  </View>
                </View>

                {/* cards container */}
                <View style={styles.cardContainer}>
                  {users ? (
                    users.map((user, index) => {
                      return (
                        <View style={styles.infoCard} key={index}>
                          <View style={styles.noCircle}>
                            <Text
                              style={styles.noCircleText}
                              render={() => `${convertToDevanagari(user.profile_number)}`}
                            />
                          </View>
                          <View style={styles.vhFlexName}>
                            <Text style={styles.vhTitleName}>नाव</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValueName}>
                            {user.last_name} {user.title}{user.first_name} {user.middle_name}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>जन्म ता./वेळ</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.dob != undefined ? convertToDevanagariDate(user.dob) : ''} |{user.day_time} {user.birth_time != undefined ? convertToDevanagariTime(user.birth_time) : ''} मि| जन्मगाव: {user.birth_place}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}> </Text>
                            <Text style={styles.vhColon}> </Text>
                            <Text style={styles.vhValue}>
                              उंची {user.height_foot}.{user.height_inch}" । {user.varna} । रक्तगट: {user.blood_group} {user.divorced == 'true' ? '| घटस्फोटित' : ''}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>शिक्षण</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {(user.profession === 'इतर पदवीधर' || user.profession === 'इतर पदविका' || user.profession === 'इतर') ? '' : user.profession} {user.education}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>नो./व्यवसाय</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.occupation}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>वार्षिक उत्पन्न</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.annual_income}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>अपेक्षा</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.expectations}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>भाऊ विवाहित</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.married_brothers + "  "} अविवाहित: {user.unmarried_brothers} {"  "}/{"  "} बहीण विवाहित: {user.married_sisters + "  "} अविवाहित: {user.unmarried_sisters}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>वडिलांचे नाव</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.parent_first_name} {user.parent_middle_name} {user.parent_last_name}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>पत्ता</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.parent_address},
                              मो.{user.parent_mobile}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhTitle}>मामकूळ</Text>
                            <Text style={styles.vhColon}>:</Text>
                            <Text style={styles.vhValue}>
                            {user.mama_first_name}, {user.mama_address}, मो.{user.mama_mobile}
                            </Text>
                          </View>
                        </View>
                      );
                    })
                  ) : (
                    ''
                  )}
                </View>

                {/* footer */}
                <View style={styles.pageNoContainer}>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber }) =>
                      `... ${convertToDevanagari(pageNumber)} ...`
                    }
                  />
                </View>
              </Page>
            );
          })
        : ""}
    </Document>
  );
};

export default BrideTextPdf;
