import React, { useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import BrideTextPdf from "./BrideTextPdf";
import PageTitle from "../components/PageTitle/PageTitle";
import ProfileService from "../services/profile.service";

function chunk(array, count) {
    if (count == null || count < 1) return [];
    var result = [];
    var i = 0, length = array.length;
    while (i < length) {
      result.push(Array.prototype.slice.call(array, i, i += count));
    }
    return result;
  };

const BrideText = () => {
    const [userList, setUserList] = useState([]);
    const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    ProfileService.getBrides()
      .then((response) => {
        setDataLength(response.data.data.length);
        setUserList(chunk(response.data.data, 10));
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  return (
    <>
    <PageTitle title="उपवधू माहिती" />
      <section className="vh_dashboard p-0">
        <PDFViewer>
          <BrideTextPdf data={userList} length={dataLength} />
        </PDFViewer>
      </section>
    </>
  );
};

export default BrideText;
