import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
} from "../redux/reducers/user/user.thunk";
import { displayErrorMessages } from "../helpers/diplayError";

const Registration = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  // const [userInfo, setUserInfo] = useState();

  const dispatch = useDispatch();
  const { isLoading, user, errorMessage } = useSelector((state) => state.user);

  const onSubmit = (data) => {
    // setUserInfo(data);
    clearAuthErrDiv();
    // console.log(data);
    dispatch(registerUser(data, props));
  };

  const clearAuthErrDiv = () => {
    let authErr = document.querySelector("#authErr");
    authErr.innerHTML = "";
  };

  const successMessage = (successMessage) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<div class="alert alert-success add-padding">' +
            " " +
            successMessage +
            "</div>",
        }}
      />
    );
  };

  return (
    <>
      <PageTitle title="रजिस्ट्रेशन" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={7}>
              {/* <pre>{JSON.stringify(userInfo, undefined, 2)}</pre> */}
              <div id="authErr"></div>
              <div className="bg-white mb-5">
              {
                /**
                 * if authResponse.success is true show success message
                 */
              user!=null? 
              successMessage(user.message)
              /**
               * else if authResponse.success == false show error messages
               */
                 : 
                 errorMessage!=null ?
                displayErrorMessages(errorMessage.error,document.getElementById('authErr')) 
                : errorMessage

              }
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <h2 className="form-title">पूर्ण फॉर्म भरा</h2>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="title">
                    <Form.Label>
                      शीर्षक <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Select
                      {...register("title", {
                        required: true,
                      })}
                    >
                      <option value="">शीर्षक निवडा</option>
                      <option value="1">कु.</option>
                      <option value="2">चि.</option>
                      <option value="3">कु. डॉ.</option>
                      <option value="3">चि. डॉ.</option>
                      <option value="3">ॲड. कु.</option>
                      <option value="3">ॲड. चि.</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.title?.type === "required" && "शीर्षक आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="first_name">
                    <Form.Label>
                      नाव <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="उदा. आशिष"
                      {...register("first_name", {
                        required: true,
                        // pattern: /^[\u0900-\u097F ,-/()]+$/,
                        pattern: /^[\u0900-\u097F]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.first_name?.type === "required" &&
                        "नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.first_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाते"}
                    </p>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="middle_name">
                    <Form.Label>
                      वडिलांचे नाव <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="उदा. रमेश"
                      {...register("middle_name", {
                        pattern: /^[\u0900-\u097F]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.middle_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाते"}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="last_name">
                    <Form.Label>
                      अडनाव <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="उदा. जाधव"
                      {...register("last_name", {
                        required: true,
                        pattern: /^[\u0900-\u097F]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.last_name?.type === "required" &&
                        "अडनाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.last_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाते"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="dob">
                    <Form.Label>
                      जन्म तारीख <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="dob"
                      {...register("dob", {
                        required: true,
                      })}
                    />
                    <p className="vh-error">
                      {errors.dob?.type === "required" &&
                        "जन्म तारीख आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="gender">
                    <Form.Label>
                      उपवधू / उपवर <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Select
                      {...register("gender", {
                        required: true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="1">उपवधू</option>
                      <option value="2">उपवर</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.gender?.type === "required" &&
                        "उपवधू / उपवर आवश्यक आहे"}
                    </p>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="email">
                    <Form.Label>
                      ई-मेल <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="उदा. ashish.jadhav@gmail.com"
                      {...register("email", {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.email?.type === "required" && "ई-मेल आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.email?.type === "pattern" &&
                        "कृपया वैध ई-मेल प्रविष्ट करा"}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="password">
                    <Form.Label>
                      पासवर्ड <span className="vh-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="उदा. min 6 characters long"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    <p className="vh-error">
                      {errors.password?.type === "required" &&
                        "पासवर्ड आवश्यक आहे"}
                    </p>
                  </Form.Group>
                </Row>
                <Button variant="danger" type="submit" className="w-100">
                  रजिस्टर करा
                </Button>
                <div className="mt-3 vh-already">
                  <p>
                    आधीच नोंदणी केली आहे?{" "}
                    <Link to="/login">येथे लॉगिन करा</Link>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Registration;
