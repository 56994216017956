import apiClient from '../helpers/apiClient';

class ProfileService {
    todaysRegistrations = () => apiClient().post('todays_registrations');
    
    allRegistrations = () => apiClient().post('all_registrations');
    allPaidUsers = () => apiClient().post('all_paid_users');
    // allRegistrations = () => apiClient().post('brides');
    // allRegistrations = () => apiClient().post('grooms');
    getBrides = () => apiClient().post('brides');
    getGrooms = () => apiClient().post('grooms');
    getPosts = () => apiClient().post('posts');

    getDashboardCount = () => apiClient().get('dashboard_count');
    loadUserProfile = (id) => apiClient().post('view_profile', id);
    updatePaymentStatus = (data) => apiClient().post('update_payment_status', data);
    updateUserProfile = (data) => apiClient().post('update_profile?_method=PUT', data);
}

export default new ProfileService();