import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../shared-components/loader";
import apiClient from "../../helpers/apiClient";
import toasty from "../../utilities/toasty";

const EditDetails = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const { id } = useParams();

  useEffect(() => {
    apiClient().get("/user-details/" + id)
        .then((response) => {
          setUser(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
  }, []); 

  // form submit
  const onSubmit = (data) => {
    setLoading(true);
    apiClient().put("/edit-details/" + id, data)
      .then((response) => {
        setLoading(false);
        props.history.push('/user-profile/' + id);
        toasty('माहिती सेव्ह झाली आहे.', 'success');
      })
      .catch((error) => {
        setLoading(false);
        toasty('माहिती सेव्ह झाली नाही.', 'error');
      });
  }
  
  return (
    <>
    <PageTitle title="प्रोफाइल नोंदणी" align="center" />
    {loading && <Loader />}
    {!loading &&
    <section>
      <Container>
        <Row className="justify-content-center">
          <Col xl={9}>
            <div className="bg-white p-4 mb-3 vh_instructions">
              <h3>नोंदणी करण्याआधी काही महत्वाच्या सूचना</h3>
              <ul>
                <li>स्टार (<span className="vh-asterisk">*</span>) केलेली माहिती देणे आवश्यक आहे</li>
                <li>नावा नंतर स्पेस देऊ नये</li>
                <li>मोबाइल मध्ये मराठी टायपिंग करीता <a href="https://www.youtube.com/watch?v=w9Lo7wh2jI0" target="_blank" rel="noopener noreferrer">इथे क्लिक करा</a></li>
                <li>वार्षिक उत्पन्न टाकताना ते <b>५०.५ हजार</b> किंवा <b>४.५ लाख</b> याच फॉरमॅट मध्ये टाका</li>
              </ul>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <h2 className="form-title">कृपया योग्य माहिती भरा</h2>

                <Row className="mb-3">
                <Form.Group as={Col} style={{'borderBottom':'1px solid #d7d7d7'}} controlId="formBasicEmail">
                  <Form.Label style={{'color':'#d4007f'}}>उप वधु / वराची माहिती</Form.Label>
                </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="gender"
                  >
                    <Form.Label>उप वधू / वर <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.gender ? user.gender : ''}
                      {...register("gender", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="उपवधू">उपवधू</option>
                      <option value="उपवर">उपवर</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.gender?.type === "required" &&
                        "उप वधू / वर आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="title"
                  >
                    <Form.Label>शीर्षक <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.title ? user.title : ''}
                      {...register("title", {
                        required:true,
                      })}
                    >
                      <option value="">शीर्षक निवडा</option>
                      <option value="कु.">कु.</option>
                      <option value="चि.">चि.</option>
                      <option value="कु.डॉ.">कु. डॉ.</option>
                      <option value="चि.डॉ.">चि. डॉ.</option>
                      <option value="ॲड.कु.">ॲड. कु.</option>
                      <option value="ॲड.चि.">ॲड. चि.</option>
                      <option value="प्रा.कु.">प्रा. कु.</option>
                      <option value="प्रा.चि.">प्रा. चि.</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.title?.type === "required" && "शीर्षक आवश्यक आहे"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="first_name"
                  >
                    <Form.Label>नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.first_name ? user.first_name : ''}
                      type="text"
                      placeholder="उदा. आशिष"
                      {...register("first_name", {
                        required:true,
                        // pattern: /^[\u0900-\u097F ,-/()]+$/,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.first_name?.type === "required" &&
                        "नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.first_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="middle_name"
                  >
                    <Form.Label>वडिलांचे नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.middle_name ? user.middle_name : ''}
                      type="text"
                      placeholder="उदा. रमेश"
                      {...register("middle_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.middle_name?.type === "required" &&
                        "वडिलांचे नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.middle_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="last_name"
                  >
                    <Form.Label>अडनाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.last_name ? user.last_name : ''}
                      type="text"
                      placeholder="उदा. जाधव"
                      {...register("last_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.last_name?.type === "required" &&
                        "अडनाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.last_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={4} className="mb-3" controlId="dob">
                    <Form.Label>जन्म तारीख <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.dob ? user.dob : ''}
                      type="date"
                      placeholder="जन्म तारीख"
                      {...register("dob", {
                        required:true,
                      })}
                    />
                    <p className="vh-error">
                      {errors.dob?.type === "required" &&
                        "जन्म तारीख आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={2}
                    className="mb-0 mb-md-3"
                    controlId="day_time"
                  >
                    <Form.Label>जन्म वेळ <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.day_time ? user.day_time : ''}
                      {...register("day_time", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="पहाटे">पहाटे</option>
                      <option value="सकाळी">सकाळी</option>
                      <option value="दुपारी">दुपारी</option>
                      <option value="सायं.">सायंकाळी</option>
                      <option value="रात्री">रात्री</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.day_time?.type === "required" &&
                        "जन्म वेळ आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={2}
                    className="mb-3"
                    controlId="birth_time"
                  >
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      defaultValue={user.birth_time ? user.birth_time : ''}
                      type="time"
                      placeholder="जन्म वेळ"
                      {...register("birth_time", {
                        required:true,
                      })}
                    />
                    <p className="vh-error">
                      {errors.birth_time?.type === "required" &&
                        "वेळ आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="birth_place"
                  >
                    <Form.Label>जन्मगाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.birth_place ? user.birth_place : ''}
                      type="text"
                      placeholder="उदा. नागपूर"
                      {...register("birth_place", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,.]+$/,
                        maxLength: 18
                      })}
                    />
                    <p className="vh-error">
                      {errors.birth_place?.type === "required" &&
                        "जन्मगाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.birth_place?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.birth_place?.type === "maxLength" &&
                        "फक्त १८ अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={2}
                    className="mb-3"
                    controlId="height_foot"
                  >
                    <Form.Label>ऊंची <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.height_foot ? user.height_foot : ''}
                      {...register("height_foot", {
                        required:true,
                      })}
                    >
                      <option value="">फुट निवडा</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                      <option value="५">५</option>
                      <option value="६">६</option>
                      <option value="७">७</option>
                      <option value="८">८</option>
                      <option value="९">९</option>
                      <option value="१०">१०</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.height_foot?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={2}
                    className="mb-3"
                    controlId="height_inch"
                  >
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Select
                        defaultValue={user.height_inch ? user.height_inch : ''}
                      {...register("height_inch", {
                        required:true,
                      })}
                    >
                      <option value="">इंच निवडा</option>
                      <option value="०">०</option>
                      <option value="१">१</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                      <option value="५">५</option>
                      <option value="६">६</option>
                      <option value="७">७</option>
                      <option value="८">८</option>
                      <option value="९">९</option>
                      <option value="१०">१०</option>
                      <option value="११">११</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.height_inch?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="varna"
                  >
                    <Form.Label>वर्ण <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.varna ? user.varna : ''}
                      {...register("varna", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="गोरा">गोरा</option>
                      <option value="निमगोरा">निमगोरा</option>
                      <option value="सावळा">सावळा</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.varna?.type === "required" && "वर्ण आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="blood_group"
                  >
                    <Form.Label>रक्तगट <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.blood_group ? user.blood_group : ''}
                      {...register("blood_group", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="ए+">ए+</option>
                      <option value="ए−">ए−</option>
                      <option value="बी+">बी+</option>
                      <option value="बी−">बी−</option>
                      <option value="एबी+">एबी+</option>
                      <option value="एबी−">एबी−</option>
                      <option value="ओ+">ओ+</option>
                      <option value="ओ−">ओ−</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.blood_group?.type === "required" &&
                        "रक्तगट आवश्यक आहे"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="profession"
                  >
                    <Form.Label>शिक्षण <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.profession ? user.profession : ''}
                      {...register("profession", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="सी.ए">सी.ए</option>
                      <option value="सी.एस">सी.एस</option>
                      <option value="एम.बी.बी.एस">एम.बी.बी.एस</option>
                      <option value="एम.डी">एम.डी</option>
                      <option value="एम.एस">एम.एस</option>
                      <option value="डी.एन.बी">डी.एन.बी</option>
                      <option value="एम.डी.एस">एम.डी.एस</option>
                      <option value="बी.डी.एस">बी.डी.एस</option>
                      <option value="एम.पी.टी">एम.पी.टी</option>
                      <option value="बी.पी.टी">बी.पी.टी</option>
                      <option value="बी.एच.एम.एस">बी.एच.एम.एस</option>
                      <option value="बी.ए.एम.एस">बी.ए.एम.एस</option>
                      <option value="एम.व्ही.एस.सी">एम.व्ही.एस.सी</option>
                      <option value="बी.व्ही.एस.सी">बी.व्ही.एस.सी</option>
                      <option value="एम.बी.ए">एम.बी.ए</option>
                      <option value="एम.ई">एम.ई</option> 
                      <option value="एम.टेक">एम.टेक</option> 
                      <option value="एम.आर्च">एम.आर्च</option> 
                      <option value="एम.डेस">एम.डेस</option> 
                      <option value="बी.ई">बी.ई</option>
                      <option value="बी.टेक">बी.टेक</option>
                      <option value="बी.आर्च">बी.आर्च</option>
                      <option value="बी.डेस">बी.डेस</option>
                      <option value="पॉलीटेकनिक">पॉलीटेकनिक</option> 
                      <option value="एल.एल.बी">एल.एल.बी</option> 
                      <option value="एल.एल.एम">एल.एल.एम</option> 
                      {/* <option value="फार्मसी">फार्मसी</option>  */}
                      <option value="डि.फार्म.">डि.फार्म.</option> 
                      <option value="बी.फार्म.">बी.फार्म.</option> 
                      <option value="एम.फार्म.">एम.फार्म.</option> 
                      <option value="एम.सी.ए">एम.सी.ए</option>
                      <option value="एम.एस.सी">एम.एस.सी</option>
                      <option value="बी.सी.ए">बी.सी.ए</option>
                      <option value="बी.एस.सी">बी.एस.सी</option>
                      <option value="बी.एस.सी.नर्सिंग">बी.एस.सी (नर्सिंग)</option>
                      <option value="एम.सी.एम">एम.सी.एम</option>
                      <option value="एम.कॉम">एम.कॉम</option>
                      <option value="बी.कॉम">बी.कॉम</option>  
                      <option value="एम.ए">एम.ए</option>
                      <option value="बी.ए">बी.ए</option>
                      <option value="एम.एड">एम.एड</option>
                      <option value="बी.एड">बी.एड</option> 
                      <option value="बी.सी.सी.ए">बी.सी.सी.ए</option>
                      <option value="इतर पदवीधर">इतर पदवीधर</option> 
                      <option value="आय.टी.आय">आय.टी.आय</option>
                      <option value="इतर पदविका">इतर पदविका</option> 
                      <option value="१२वी">१२वी</option> 
                      <option value="१०वी">१०वी</option>
                      <option value="इतर">इतर</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.profession?.type === "required" &&
                        "शिक्षणाचे क्षेत्र आवश्यक आहे"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="education"
                  >
                    <Form.Label>इतर शिक्षण</Form.Label>
                    <Form.Control
                      defaultValue={user.education ? user.education : ''}
                      type="text"
                      placeholder="उदा. (कॉम्पुटर)"
                      {...register("education", {
                        pattern: /^[\u0900-\u097F ,-/().]*$/,
                        maxLength: 60
                      })}
                    />
                    <p className="vh-error">
                      {errors.education?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.education?.type === "maxLength" &&
                        "फक्त ६० अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                  
                  <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="occupation"
                  >
                    <Form.Label>नोकरी / व्यवसाय / (शेती असल्यास एकर मध्ये लिहावे) <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.occupation ? user.occupation : ''}
                      type="text"
                      placeholder="उदा. सॉफ्टवेअर डेव्हलपर"
                      {...register("occupation", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,-/().]*$/,
                        maxLength: 60
                      })}
                    />
                    <p className="vh-error">
                      {errors.occupation?.type === "required" && "आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.occupation?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.occupation?.type === "maxLength" &&
                        "फक्त ६० अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    className="mb-3"
                    controlId="address"
                  >
                    <Form.Label>नोकरी / व्यवसाय पत्ता</Form.Label>
                    <Form.Control
                      defaultValue={user.address ? user.address : ''}
                      type="text"
                      placeholder="उदा. विप्रो, पुणे"
                      {...register("address", {
                        pattern: /^[\u0900-\u097F ,-/().]*$/,
                        maxLength: 60
                      })}
                    />
                    <p className="vh-error">
                      {errors.address?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.address?.type === "maxLength" &&
                        "फक्त ६० अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="annual_income"
                  >
                    <Form.Label>वार्षिक उत्पन्न <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.annual_income ? user.annual_income : ''}
                      type="text"
                      placeholder="उदा. ५.३ लाख"
                      {...register("annual_income", {
                        required:true,
                        pattern: /^[\u0900-\u097F .]+$/,
                        maxLength: 12
                      })}
                    />
                    <p className="vh-error">
                      {errors.annual_income?.type === "required" &&
                        "वार्षिक उत्पन्न आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.annual_income?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.annual_income?.type === "pattern" &&
                        "फक्त १२ अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>

                  
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="expectations"
                  >
                    <Form.Label>अपेक्षा <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.expectations ? user.expectations : ''}
                      type="text"
                      placeholder="उदा. अनुरूप"
                      {...register("expectations", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,()]+$/,
                        maxLength: 22
                      })}
                    />
                    <p className="vh-error">
                      {errors.expectations?.type === "required" &&
                        "अपेक्षा आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.expectations?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.expectations?.type === "maxLength" &&
                        "फक्त २२ अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                  
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="divorced"
                  >
                    <Form.Label>घटस्फोटित? <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.divorced ? user.divorced : ''}
                      {...register("divorced", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="true">हो</option>
                      <option value="false">नाही</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.divorced?.type === "required" && "शीर्षक आवश्यक आहे"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="married_brothers"
                  >
                    <Form.Label>विवाहित भाऊ <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.married_brothers ? user.married_brothers : ''}
                      {...register("married_brothers", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="-">०</option>
                      <option value="१">१</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.married_brothers?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="unmarried_brothers"
                  >
                    <Form.Label>अविवाहित भाऊ <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.unmarried_brothers ? user.unmarried_brothers : ''}
                      {...register("unmarried_brothers", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="-">०</option>
                      <option value="१">१</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.unmarried_brothers?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="married_sisters"
                  >
                    <Form.Label>विवाहित बहीण <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.married_sisters ? user.married_sisters : ''}
                      {...register("married_sisters", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="-">०</option>
                      <option value="१">१</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.married_sisters?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="unmarried_sisters"
                  >
                    <Form.Label>अविवाहित बहीण <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.unmarried_sisters ? user.unmarried_sisters : ''}
                      {...register("unmarried_sisters", {
                        required:true,
                      })}
                    >
                      <option value="">निवडा</option>
                      <option value="-">०</option>
                      <option value="१">१</option>
                      <option value="२">२</option>
                      <option value="३">३</option>
                      <option value="४">४</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.unmarried_sisters?.type === "required" &&
                        "आवश्यक आहे"}
                    </p>
                  </Form.Group>

                </Row>

                <Row className="mb-3">
                <Form.Group as={Col} style={{'borderBottom':'1px solid #d7d7d7'}} controlId="formBasicEmail">
                  <Form.Label style={{'color':'#d4007f'}}>वडील / पालकाची माहिती</Form.Label>
                </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="parent_first_name"
                  >
                    <Form.Label>वडील / पालकांचे नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.parent_first_name ? user.parent_first_name : ''}
                      type="text"
                      placeholder="उदा. रमेश"
                      {...register("parent_first_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_first_name?.type === "required" &&
                        "नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_first_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="parent_middle_name"
                  >
                    <Form.Label>वडील / पालकांचे मधले नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.parent_middle_name ? user.parent_middle_name : ''}
                      type="text"
                      placeholder="उदा. शंकर"
                      {...register("parent_middle_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_middle_name?.type === "required" &&
                        "मधले नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_middle_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="parent_last_name"
                  >
                    <Form.Label>वडील / पालकांचे अडनाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.parent_last_name ? user.parent_last_name : ''}
                      type="text"
                      placeholder="उदा. जाधव"
                      {...register("parent_last_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_last_name?.type === "required" &&
                        "अडनाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_last_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={8}
                    className="mb-3"
                    controlId="parent_address"
                  >
                    <Form.Label>वडील / पालकांचा संपूर्ण पत्ता <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.parent_address ? user.parent_address : ''}
                      type="text"
                      placeholder="उदा. विमल नगर, फरशी स्टॉप, मंदिराच्या मागे, अमरावती"
                      {...register("parent_address", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,-/()]+$/,
                        maxLength: 50
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_address?.type === "required" &&
                        "संपूर्ण पत्ता आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_address?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_address?.type === "maxLength" &&
                        "फक्त ५० अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="parent_mobile"
                  >
                    <Form.Label>वडील / पालकांचे मोबाइल नं. <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.parent_mobile ? user.parent_mobile : ''}
                      type="text"
                      placeholder="उदा. ११२८९३८३९२"
                      {...register("parent_mobile", {
                        required:true,
                        pattern: /^[\u0966-\u096F]+$/,
                        maxLength: 10
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_mobile?.type === "required" &&
                        "मोबाइल नं. आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_mobile?.type === "pattern" &&
                        "फक्त मराठी अंक स्वीकारले जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_mobile?.type === "maxLength" &&
                        "कृपया वैध मोबाइल नं. टाईप करा"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={8}
                    className="mb-3"
                    controlId="parent_occupation"
                  >
                    <Form.Label>
                      वडील / पालकांचे नोकरी / व्यवसाय / (शेती असल्यास एकर मध्ये लिहावे)
                    </Form.Label>
                    <Form.Control
                      defaultValue={user.parent_occupation ? user.parent_occupation : ''}
                      type="text"
                      placeholder="उदा. पोलीस"
                      {...register("parent_occupation", {
                        pattern: /^[\u0900-\u097F ,-/().]*$/,
                        maxLength: 26
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_occupation?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_occupation?.type === "maxLength" &&
                        "फक्त 26 अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="parent_annual_income"
                  >
                    <Form.Label>वडील / पालकांचे वार्षिक उत्पन्न</Form.Label>
                    <Form.Control
                      defaultValue={user.parent_annual_income ? user.parent_annual_income : ''}
                      type="text"
                      placeholder="४.५ लाख"
                      {...register("parent_annual_income", {
                        pattern: /^[\u0900-\u097F .]+$/,
                        maxLength: 12
                      })}
                    />
                    <p className="vh-error">
                      {errors.parent_annual_income?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.parent_annual_income?.type === "pattern" &&
                        "फक्त १२ अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group as={Col} style={{'borderBottom':'1px solid #d7d7d7'}} controlId="formBasicEmail">
                  <Form.Label style={{'color':'#d4007f'}}>मामाची माहिती</Form.Label>
                </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="mama_first_name"
                  >
                    <Form.Label>मामाचे संपूर्ण नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.mama_first_name ? user.mama_first_name : ''}
                      type="text"
                      placeholder="उदा. रवी"
                      {...register("mama_first_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,-/()]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.mama_first_name?.type === "required" &&
                        "नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.mama_first_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="mama_address"
                  >
                    <Form.Label>मामाचे गाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.mama_address ? user.mama_address : ''}
                      type="text"
                      placeholder="उदा. बाभुळगांव, यवतमाळ"
                      {...register("mama_address", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,-/()]+$/,
                        maxLength: 50
                      })}
                    />
                    <p className="vh-error">
                      {errors.mama_address?.type === "required" &&
                        "संपूर्ण पत्ता आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.mama_address?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.mama_address?.type === "maxLength" &&
                        "फक्त ५० अक्षरांतच हि माहिती भरा"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="mama_mobile"
                  >
                    <Form.Label>मामाचा मोबाइल नं. <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.mama_mobile ? user.mama_mobile : ''}
                      type="text"
                      placeholder="उदा. १२८३९२८३९२"
                      {...register("mama_mobile", {
                        required:true,
                        pattern: /^[\u0966-\u096F]+$/,
                        maxLength: 10
                      })}
                    />
                    <p className="vh-error">
                      {errors.mama_mobile?.type === "required" &&
                        "मोबाइल नं. आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.mama_mobile?.type === "pattern" &&
                        "फक्त मराठी अंक स्वीकारले जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.mama_mobile?.type === "maxLength" &&
                        "कृपया वैध मोबाइल नं. टाईप करा"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group as={Col} style={{'borderBottom':'1px solid #d7d7d7'}} controlId="formBasicEmail">
                  <Form.Label style={{'color':'#d4007f'}}>माहिती पुस्तिका मिळविण्याकरीता पत्र व्यवहाराची संपुर्ण माहिती</Form.Label>
                </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_first_name"
                  >
                    <Form.Label>नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_first_name ? user.post_first_name : ''}
                      type="text"
                      placeholder="उदा. रवी"
                      {...register("post_first_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_first_name?.type === "required" &&
                        "नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_first_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_middle_name"
                  >
                    <Form.Label>मधले नाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_middle_name ? user.post_middle_name : ''}
                      type="text"
                      placeholder="उदा. नागोराव"
                      {...register("post_middle_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_middle_name?.type === "required" &&
                        "मधले नाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_middle_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_last_name"
                  >
                    <Form.Label>अडनाव <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_last_name ? user.post_last_name : ''}
                      type="text"
                      placeholder="उदा. फितवे"
                      {...register("post_last_name", {
                        required:true,
                        pattern: /^[\u0900-\u097F ]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_last_name?.type === "required" &&
                        "अडनाव आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_last_name?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md={8}
                    className="mb-3"
                    controlId="post_address"
                  >
                    <Form.Label>संपूर्ण पत्ता <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_address ? user.post_address : ''}
                      type="text"
                      placeholder="उदा. विमल नगर, फरशी स्टॉप, मंदिराच्या मागे, अमरावती"
                      {...register("post_address", {
                        required:true,
                        pattern: /^[\u0900-\u097F ,-/()]+$/,
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_address?.type === "required" &&
                        "संपूर्ण पत्ता आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_address?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_district"
                  >
                    <Form.Label>जिल्हा <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Select
                      defaultValue={user.post_district ? user.post_district : ''}
                      {...register("post_district", {
                        required:true,
                      })}
                    >
                      <option value="">जिल्हा निवडा</option>
                      <option value="मुंबई शहर">मुंबई शहर</option>
                      <option value="मुंबई उपनगर">मुंबई उपनगर</option>
                      <option value="ठाणे">ठाणे</option>
                      <option value="पालघर">पालघर</option>
                      <option value="रायगड">रायगड</option>
                      <option value="रत्नागिरी">रत्नागिरी</option>
                      <option value="सिंधुदुर्ग">सिंधुदुर्ग</option>
                      <option value="नाशिक">नाशिक</option>
                      <option value="अहमदनगर">अहमदनगर</option>
                      <option value="धुळे">धुळे</option>
                      <option value="नंदुरबार">नंदुरबार</option>
                      <option value="जळगाव">जळगाव</option>
                      <option value="पुणे">पुणे</option>
                      <option value="सातारा">सातारा</option>
                      <option value="सांगली">सांगली</option>
                      <option value="कोल्हापूर">कोल्हापूर</option>
                      <option value="सोलापूर">सोलापूर</option>
                      <option value="औरंगाबाद">औरंगाबाद</option>
                      <option value="जालना">जालना</option>
                      <option value="बीड">बीड</option>
                      <option value="परभणी">परभणी</option>
                      <option value="हिंगोली">हिंगोली</option>
                      <option value="उस्मानाबाद">उस्मानाबाद</option>
                      <option value="लातूर">लातूर</option>
                      <option value="नांदेड">नांदेड</option>
                      <option value="अमरावती">अमरावती</option>
                      <option value="बुलढाणा">बुलढाणा</option>
                      <option value="अकोला">अकोला</option>
                      <option value="वाशीम">वाशीम</option>
                      <option value="यवतमाळ">यवतमाळ</option>
                      <option value="नागपूर">नागपूर</option>
                      <option value="वर्धा">वर्धा</option>
                      <option value="भंडारा">भंडारा</option>
                      <option value="गोंदिया">गोंदिया</option>
                      <option value="चंद्रपूर">चंद्रपूर</option>
                      <option value="गडचिरोली">गडचिरोली</option>
                      <option value="महाराष्ट्रा बाहेरील">महाराष्ट्रा बाहेरील</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.post_district?.type === "required" &&
                        "जिल्हा आवश्यक आहे"}
                    </p>
                  </Form.Group>
                
                </Row>

                <Row className="mb-3">
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_pincode"
                  >
                    <Form.Label>पिनकोड <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_pincode ? user.post_pincode : ''}
                      type="text"
                      placeholder="उदा. ४४५००१"
                      {...register("post_pincode", {
                        required:true,
                        pattern: /^[\u0966-\u096F]+$/,
                        maxLength: 6
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_pincode?.type === "required" &&
                        "पिनकोड आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_pincode?.type === "pattern" &&
                        "फक्त मराठी स्वीकारली जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.post_pincode?.type === "maxLength" &&
                        "कृपया वैध पिनकोड टाईप करा"}
                    </p>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="post_mobile"
                  >
                    <Form.Label>मोबाइल नं. <span className="vh-asterisk">*</span></Form.Label>
                    <Form.Control
                      defaultValue={user.post_mobile ? user.post_mobile : ''}
                      type="text"
                      placeholder="उदा. ११२८९३८३९२"
                      {...register("post_mobile", {
                        required:true,
                        pattern: /^[\u0966-\u096F]+$/,
                        maxLength: 10
                      })}
                    />
                    <p className="vh-error">
                      {errors.post_mobile?.type === "required" &&
                        "मोबाइल नं. आवश्यक आहे"}
                    </p>
                    <p className="vh-error">
                      {errors.post_mobile?.type === "pattern" &&
                        "फक्त मराठी अंक स्वीकारले जाईल"}
                    </p>
                    <p className="vh-error">
                      {errors.post_mobile?.type === "maxLength" &&
                        "कृपया वैध मोबाइल नं. टाईप करा"}
                    </p>
                  </Form.Group>

                  </Row>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>नोटिस</Form.Label>
                  <div></div>
                  <Form.Text className="text-muted">
                    नोंदणी फार्म मध्ये लिहिलेली माहिती पूर्णपणे खरी आहे. माहिती
                    चुकीची आढळल्यास त्याकरिता पालक स्वत: जबाबदार राहतील, मंडळ
                    जबाबदार राहणार नाही.
                  </Form.Text>
                </Form.Group>
                <Button
                  variant="danger"
                  type="submit"
                  className="w-100 mx-auto"
                  style={{ maxWidth: "290px" }}
                >
                  सेव्ह करा आणि प्रोफाइल ला जा
                </Button>
              </Form>
          </Col>
        </Row>
      </Container>
    </section>
    }
  </>
  )
}

export default EditDetails;