import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";

const GroomsProfile = () => {
  return (
    <>
      <PageTitle title="माझे प्रोफाइल" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="mb-3">
              <div className="info-container">
                <h4>उपवर माहिती</h4>
                <div className="user-profile">
                  <div className="user-image">
                  <div className="user-no">९९२</div>
                    <img
                      src="https://localhost:3000/assets/img/profile.jpg"
                      className="img-fluid"
                      alt="profile picture"
                    />
                    <h2>ठोंबरे चि.मयुरेश</h2>
                  </div>
                  <div className="user-details">
                  <div className="user-no">९९२</div>
                    <div className="vh-flex user-name">
                      <p className="vh-flex-title">नाव</p>
                      <p className="vh-flex-value">ठोंबरे चि.मयुरेश सुभाष</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">जन्म ता./वेळ</p>
                      <p className="vh-flex-value">२/११/१९९२|सकाळी ०५.०० मि.|जन्मगाव: यवतमाळ</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">उंची</p>
                      <p className="vh-flex-value">५.८" । गोरा । रक्तगट: ए+</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">शिक्षण</p>
                      <p className="vh-flex-value">बी.ई. मेकॅनिकल (अमरावती युनिव्हर्सिटी)</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">नो./व्यवसाय</p>
                      <p className="vh-flex-value">अकॉउंट मॅनेजर कं. एमवायओबी ऑस्ट्रेलिया मेलबर्न</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">अपेक्षा:</p>
                      <p className="vh-flex-value">अनुरूप, इंजिनिअर असावी &nbsp; वार्षिक उत्पन्न: ४०.२० लाख</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">भाऊ विवाहित</p>
                      <p className="vh-flex-value">- &nbsp; अविवाहित: - / बहीण विवाहित: १ &nbsp; अविवाहित: -</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">वडिलांचे नाव</p>
                      <p className="vh-flex-value">सुभाष आप्पाजी ठोंबरे (सेवानिवृत्त, कृषी
                      अधिकारी)</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">पत्ता</p>
                      <p className="vh-flex-value">२९ भारती नगर, उद्योग भवन समोर, नाईस कॉम्पुटर जवळ,
                      यवतमाळ मो. ८१९२८३८३९२ वार्षिक उत्पन्न: ६ लाख</p>
                    </div>

                    <div className="vh-flex">
                      <p className="vh-flex-title">मामकूळ</p>
                      <p className="vh-flex-value">रवी मनोहर दलाल, मु. पो. तामसा, ता. हदगांव, जि. नांदेड मो. ८८१९२८३८३९</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GroomsProfile;
