import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ProfileService from "../../services/profile.service";
import { convertToEnglish } from "../../helpers/utility";
import Urls from "../../constants/urls";

const TodaysRegistrations = () => {
  const [userList, setUserList] = useState([]);

  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: "Username",
      selector: (row) => (
        <Link to={`user-profile/${row.id}`} style={{'color':'#707070', 'textDecoration':'underline'}}>
          {row.username}
        </Link>
      ),
      width: "150px"
    },
    {
      name: "Contact",
      selector: (row) => (
        <a href={`tel:${convertToEnglish(row.mobile)}`}>
          {row.mobile}
        </a>
      ),
      width: "130px"
    },
    {
      name: "फोटो",
      selector: (row) => (
        <img
          className="img-fluid"
          src={`${Urls.BACKEND_IMAGE_BASE_URL}/${row.photo}`}
        />
      ),
    },
    {
      name: "पूर्ण नाव",
      selector: (row) => (
        <Link to={`user-profile/${row.id}`}>
          {row.last_name} {row.title}{row.first_name} {row.middle_name}
        </Link>
      ),
      width: "230px"
    },
    {
      name: "पेमेंट स्टेटस",
      cell: (row) => (
        row.payment_status == 'Paid' ? <Badge bg="success">Paid</Badge> : <Badge bg="danger">Unpaid</Badge>
      ),
      selector: "payment_status",
      sortable: true,
      width: "100px"
    },
    {
      name: "ऑर्डर ID",
      selector: "razorpay_order_id",
      width: "220px"
    },
    {
      name: "पेमेंट ID",
      selector: "razorpay_payment_id",
      width: "220px"
    },
  ];

  useEffect(() => {
    ProfileService.todaysRegistrations()
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <DataTable columns={columns} data={userList} striped={true} highlightOnHover={true} />
    </>
  );
};

export default TodaysRegistrations;
