import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { convertToDevanagari, convertToDevanagariDate, convertToDevanagariTime } from "../helpers/utility";
import Urls from "../constants/urls";

// Register font
Font.register({
  family: "Noto Sans",
  src: `${Urls.APP_URL}/assets/fonts/notosans-regular.ttf`,
});
Font.register({
  family: "Noto Sans Bold",
  src: `${Urls.APP_URL}/assets/fonts/notosans-bold.ttf`,
});

// PDF styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
    // height: "793.7pt",
    fontFamily: "Noto Sans",
    // backgroundColor: "#d7d7d7",
    paddingLeft: "45.35pt",
    paddingTop: "28.35pt",
    // paddingBottom: "28.35pt",
    paddingRight: "36.85pt",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "2pt solid #000000",
    width: "518.74pt",
    minHeight: "19.84pt",
    fontFamily: "Noto Sans Bold",
  },
  eventNameContainer: {
    flex: "6",
  },
  eventName: {
    fontSize: "12.5pt",
    paddingLeft: "5pt",
  },
  sideTextContainer: {
    backgroundColor: "#000000",
    flex: "1",
    borderTopLeftRadius: "10pt",
    borderTopRightRadius: "10pt",
    borderBottomRightRadius: "10pt",
    borderBottomLeftRadius: "10pt",
  },
  sideText: {
    color: "#ffffff",
    fontSize: "14pt",
    textAlign: "center",
    marginTop: "-2pt",
  },
  pageNoContainer: {
    // backgroundColor: 'red',
    width: "520.6pt",
    height: "15pt",
    minHeight: "15pt",
    position: "absolute",
    bottom: "15pt",
    left: "45.35pt",
    zIndex: "9999",
  },
  pageNo: {
    textAlign: "center",
    color: "#000000",
    fontSize: "10pt",
    minHeight: "15pt",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // backgroundColor: "#e7e7e7",
    marginTop: "3pt",
    paddingTop: "2pt",
    paddingLeft: "2pt",
    // minHeight: "714.33pt",
    // border: "1pt solid #000000",
  },
  infoCard: {
    position: "relative",
    width: "250.3pt",
    height: "80pt",
    minHeight: "80pt",
    border: "1pt solid #d7d7d7",
    padding: "3pt",
    margin: '2pt'
  },
  vhFlex: {
    position: "relative",
    zIndex: "99",
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "#fcdccc",
    fontSize: "8.5pt",
  },
  vhTitle: {
    width: "48.19pt",
    minWidth: "48.19pt",
    paddingLeft: "2pt",
  },
  vhColon: {
    width: "5pt",
  },
  vhValue: {
    width: "247.11pt",
    paddingRight: "2pt",
    fontSize: "11pt",
    color: "#000066"
  },
  vhFlexName: {
    position: "relative",
    zIndex: "99",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: "#fcdccc",
    fontSize: "11.5pt",
  },
  vhTitleName: {
    width: "48.19pt",
    minWidth: "48.19pt",
    paddingLeft: "2pt",
    fontSize: "11.5pt",
    fontFamily: "Noto Sans Bold",
    color: "#000088"
  },
  vhValueName: {
    width: "247.11pt",
    paddingRight: "2pt",
    fontSize: "13.5pt",
    fontFamily: "Noto Sans Bold",
    color: "#000066"
  },
});

const PostPdf = (props) => {
  return (
    <Document>
      {props.data
        ? props.data.map((users, index) => {
            return (
              <Page style={styles.page} size={[860, 1280]} key={index}>
                
                {/* cards container */}
                <View style={styles.cardContainer}>
                  {users ? (
                    users.map((user, index) => {
                      return (
                        <View style={styles.infoCard} key={index}>
                          <View style={styles.vhFlexName}>
                            <Text style={styles.vhValueName}>
                            {user.profile_number} | {user.post_last_name} {user.title}{user.post_first_name} {user.post_middle_name}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhValue}>
                            {user.post_address} {user.post_district == "महाराष्ट्रा बाहेरील" ? '' : user.post_district} {" - "} {user.post_pincode}
                            </Text>
                          </View>

                          <View style={styles.vhFlex}>
                            <Text style={styles.vhValue}>
                            मो.{user.post_mobile}
                            </Text>
                          </View>

                        </View>
                      );
                    })
                  ) : (
                    ''
                  )}
                </View>

                {/* footer */}
                <View style={styles.pageNoContainer}>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber }) =>
                      `... ${convertToDevanagari(pageNumber)} ...`
                    }
                  />
                </View>
              </Page>
            );
          })
        : ""}
    </Document>
  );
};

export default PostPdf;
