export const LoggedInMenuList = [
    {
        title: "डॅशबोर्ड",
        url: "/dashboard"
    },
    {
        title: "उपवधू",
        url: "/bride-home"
    },
    {
        title: "उपवर",
        url: "/groom-home"
    },
    {
        title: "सर्व नोंदणी",
        url: "/all-registrations"
    },
    {
        title: "सर्व नोंदणी (Excel)",
        url: "/users-excel"
    },
    {
        title: "पोस्ट",
        url: "/addresses"
    },
]