import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../redux/reducers/user/user.thunk";
import PageTitle from "../components/PageTitle/PageTitle";
import { displayErrorMessages } from "../helpers/diplayError";

const Home = (props) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  // const [userInfo, setUserInfo] = useState();

  const dispatch = useDispatch();
  const { isLoading, user, errorMessage } = useSelector((state) => state.user);

  const onSubmit = (data) => {
    // setUserInfo(data);
    clearAuthErrDiv();
    // console.log(data);
    dispatch(userLogin(data, props));
  };

  const clearAuthErrDiv = () => {
    let authErr = document.querySelector("#authErr");
    authErr.innerHTML = "";
  };

  const successMessage = (successMessage) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<div class="alert alert-success add-padding">' +
            " " +
            successMessage +
            "</div>",
        }}
      />
    );
  };

  return (
    <>
      <section className="admin_login">
        <Container>
          <Row className="justify-content-center">
            <h2 className="vh_fancy_text">लॉगिन</h2>
            <Col md={6}>
              {/* <pre>{JSON.stringify(userInfo, undefined, 2)}</pre> */}
              <div id="authErr"></div>
              <div>
                {
                  /**
                   * if authResponse.success is true show success message
                   */
                  user != null
                    ? successMessage(user.message)
                    : /**
                     * else if authResponse.success == false show error messages
                     */
                    errorMessage != null
                    ? displayErrorMessages(
                        errorMessage.message,
                        document.getElementById("authErr")
                      )
                    : errorMessage
                }
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>
                    ई-मेल <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="उदा. ashish.jadhav@gmail.com"
                    {...register("email", {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    })}
                  />
                  <p className="vh-error">
                    {errors.email?.type === "required" && "ई-मेल आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.email?.type === "pattern" &&
                      "कृपया वैध ई-मेल प्रविष्ट करा"}
                  </p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>
                    पासवर्ड <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="आपला पासवर्ड"
                    {...register("password", {
                      required: true,
                    })}
                  />
                  <p className="vh-error">
                    {errors.password?.type === "required" &&
                      "पासवर्ड आवश्यक आहे"}
                  </p>
                </Form.Group>

                <Button variant="danger" type="submit" className="w-100">
                  लॉगिन
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
