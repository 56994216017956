import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import {
  convertToDevanagari,
} from "../helpers/utility";
import Urls from "../constants/urls";

// Register font
Font.register({
  family: "Noto Sans",
  src: `${Urls.APP_URL}/assets/fonts/notosans-regular.ttf`,
});
Font.register({
  family: "Noto Sans Bold",
  src: `${Urls.APP_URL}/assets/fonts/notosans-regular.ttf`,
});

// PDF styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
    fontFamily: "Noto Sans",
    backgroundColor: "#ffd1e6",
    paddingLeft: "45.35pt",
    paddingTop: "51.02pt",
    paddingRight: "36.85pt",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "518.74pt",
    minHeight: "19.84pt",
    fontFamily: "Noto Sans Bold",
    borderRadius: "50pt",
    backgroundColor: "#e91e63",
  },
  eventNameContainer: {
    flex: "1",
  },
  eventName: {
    fontSize: "12.5pt",
    paddingLeft: "5pt",
    textAlign: "center",
    color: "#ffffff",
  },
  pageNoContainer: {
    // backgroundColor: 'red',
    width: "520.6pt",
    height: "15pt",
    minHeight: "15pt",
    position: "absolute",
    bottom: "15pt",
    left: "45.35pt",
    zIndex: "9999",
  },
  pageNo: {
    textAlign: "center",
    color: "#000000",
    fontSize: "10pt",
    minHeight: "15pt",
  },
  cardContainer: {
    width: '518.74pt',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#ffe6f1",
    marginTop: "3pt",
    paddingTop: "3pt",
    paddingLeft: "3pt",
  },
  infoCard: {
    position: "relative",
    width: "85.82pt",
    height: "130pt",
    minHeight: "130pt",
    // border: "2pt solid #000000",
    padding: "1pt",
    // marginRight: '3pt',
  },
  noCircle: {
    backgroundColor: "#1e2f8e",
    color: "#ffffff",
    fontSize: "8pt",
    // fontFamily: "Vesper Libre",
    textAlign: "center",
    position: "absolute",
    left: "2pt",
    top: "1pt",
    width: "20pt",
    height: "13pt",
    zIndex: "0",
    border: "1pt solid #ffffff",
    borderTopLeftRadius: "100%",
    borderTopRightRadius: "100%",
    borderBottomRightRadius: "100%",
    borderBottomLeftRadius: "100%",
  },
  noCircleText: {
    marginTop: "-0.5pt",
    fontFamily: "Noto Sans",
  },
  nameContainer: {
    width: "82.99pt",
  },
  name: {
    textAlign: "center",
    color: "#1e2f8e",
    fontSize: "9.5pt",
    fontFamily: "Noto Sans Bold",
  },
  overPhoto: {
    width: "82.99pt",
    height: "2.83pt",
    backgroundColor: "#e91e63",
    marginBottom: "1pt",
  },
  photoContainer: {
    width: "82.99pt",
    height: "98.88pt",
    position: "relative",
    zIndex: "-1",
    border: "1pt solid #e91e63",
  },
  photo: {
    position: "relative",
    zIndex: "-1",
    width: "80.99pt",
    height: "96.88pt",
  },
});

const BridePhotoPdf = (props) => {
  return (
    <Document>
      {props.data
        ? props.data.map((users, index) => {
            return (
              <Page style={styles.page} size={[600.94, 793.7]} key={index}>
                {/* Page title */}
                <View style={styles.pageTitle} fixed>
                  <View style={styles.eventNameContainer}>
                    <Text style={styles.eventName}>
                      वीरशैव हितसंवर्धक मंडळ, यवतमाळ लिंगायत समाज उपवधू-उपवर
                      परिचय मेळावा - २०२४
                    </Text>
                  </View>
                </View>

                {/* cards container */}
                <View style={styles.cardContainer}>
                  {users
                    ? users.map((user, index) => {
                        return (
                          <View style={styles.infoCard} key={index}>
                            {/* <Image src={`http://localhost:8000/uploads/${user.photo}`} style={styles.photo} /> */}
                            <View style={styles.overPhoto}>
                              <Text> </Text>
                            </View>
                            <View style={styles.photoContainer}>
                              <Image
                                src={`${Urls.IMAGE_BASE_URL}/${user.photo}`}
                                // src={`http://localhost:3000/uploads/${user.photo}`}
                                // src={`http://localhost:3000/uploads/ठेंगे-कु.डॉ.चित्रलेखा-1629803829.jpg`}
                                // src={`http://localhost:3000/assets/img/adhar.jpg`}
                                // src={`https://upload.wikimedia.org/wikipedia/en/a/a9/Example.jpg`}
                                // src={{ uri: `http://backend.lingayatyavatmal.com/uploads/${user.photo}`, method: "GET", headers: { 'Access-Control-Allow-Origin': '*', "Cache-Control": "no-cache" }, body: "" }}
                                // source={{
                                //   uri: `${Urls.IMAGE_BASE_URL}/${user.photo}`,
                                //   headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
                                // }}
                                style={styles.photo}
                              />
                            </View>
                            <View style={styles.nameContainer}>
                              <Text style={styles.name}>
                                {user.last_name} {user.title}
                                {user.first_name}
                              </Text>
                            </View>
                            <View style={styles.noCircle}>
                              <Text
                                style={styles.noCircleText}
                                render={() =>
                                  `${convertToDevanagari(
                                    user.profile_number
                                  )}`
                                }
                              />
                            </View>
                          </View>
                        );
                      })
                    : ""}
                </View>

                {/* footer */}
                {/* <View style={styles.pageNoContainer}>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber }) =>
                      `... ${convertToDevanagari(pageNumber)} ...`
                    }
                  />
                </View> */}
              </Page>
            );
          })
        : ""}
    </Document>
  );
};

export default BridePhotoPdf;
