import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";
import AllRegistrationsTable from "../components/AllRegistrations/AllRegistrationsTable";

const AllRegistrations = () => {
  return (
    <>
      <PageTitle title="सर्व नोंदणी" />
      <section className="vh_dashboard">
        <Container className="mt-3">
          <Row>
            <Col md={12}>
              <div className="vh_card">
                <h2 className="section-title" style={{'fontSize':'1.5rem'}}>सर्व नोंदणी</h2>
                <AllRegistrationsTable />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AllRegistrations;
