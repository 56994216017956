const Urls = {
  APP_URL: 'https://admin.lingayatyavatmal.com',
  API_BASE_URL: 'https://lingayatyavatmal.com/backend/admin/',
  IMAGE_BASE_URL: 'https://admin.lingayatyavatmal.com/uploads',
  BACKEND_IMAGE_BASE_URL: 'https://lingayatyavatmal.com/backend/uploads',
};

export default Urls;

// const Urls = {
//   APP_URL: 'http://localhost:3000/',
//   API_BASE_URL: 'http://localhost:8000/admin/',
//   IMAGE_BASE_URL: 'http://localhost:3000/uploads',
// };

// export default Urls;