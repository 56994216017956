import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Dashboard from '../pages/Dashboard';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import UserProfile from '../pages/UserProfile';
import NotFound from '../pages/NotFound';
import ThankYou from '../pages/ThankYou';
import ScrollToTop from '../helpers/scroll-to-top';
import GroomsList from '../pages/BrideTextPdf';
import GroomsProfile from '../pages/GroomsProfile';
import TableView from '../pages/TableView';
import PrivateRoute from './PrivateRoute';
import BrideHome from '../pages/BrideHome';
import BridePhoto from '../pages/BridePhoto';
import BrideText from '../pages/BrideText';
import GroomHome from '../pages/GroomHome';
import GroomPhoto from '../pages/GroomPhoto';
import GroomText from '../pages/GroomText';
import AllRegistrations from '../pages/AllRegistrations';
import Post from '../pages/Post';
import UploadImage from '../pages/uploadImage';
import EditDetails from '../pages/editDetails';
import AllUsersExcel from '../pages/allUsersExcel/AllUsersExcel';
import BridesExcel from '../pages/bridesExcel/BridesExcel';
import GroomsExcel from '../pages/groomsExcel/GroomsExcel';

const Routes = () => {
    return (
        <>
            <Router>
                <ScrollToTop />
                <Header />
                <Switch>
                    <Route
                        path="/" exact component={Login}
                    />
                    <PrivateRoute
                        path="/dashboard" component={Dashboard}
                    />
                    <Route
                        path="/contact" component={Contact}
                    />
                    <Route
                        path="/login" component={Login}
                    />
                    <Route
                        path="/registration" component={Registration}
                    />
                    {/* <Route
                        path="/my-profile" component={MyProfile}
                    /> */}
                    <Route
                        path="/thank-you" component={ThankYou}
                    />
                    <Route
                        path="/grooms-list" component={GroomsList}
                    />
                    <Route
                        path="/grooms-profile" component={GroomsProfile}
                    />
                    <Route
                        path="/table-view" component={TableView}
                    />
                    <PrivateRoute exact path='/user-profile/:id' component={UserProfile} />
                    <PrivateRoute exact path='/bride-home' component={BrideHome} />
                    <PrivateRoute exact path='/bride-photo' component={BridePhoto} />
                    <PrivateRoute exact path='/bride-text' component={BrideText} />
                    <PrivateRoute exact path='/groom-home' component={GroomHome} />
                    <PrivateRoute exact path='/groom-photo' component={GroomPhoto} />
                    <PrivateRoute exact path='/groom-text' component={GroomText} />
                    <PrivateRoute exact path='/all-registrations' component={AllRegistrations} />
                    <PrivateRoute exact path='/users-excel' component={AllUsersExcel} />
                    <PrivateRoute exact path='/brides-excel' component={BridesExcel} />
                    <PrivateRoute exact path='/grooms-excel' component={GroomsExcel} />
                    <PrivateRoute exact path='/addresses' component={Post} />
                    <PrivateRoute exact path='/upload-image/:id' component={UploadImage} />
                    <PrivateRoute exact path='/edit-details/:id' component={EditDetails} />
                    {/* <Guard path="/my-profile" token="user-token" routeRedirect="/login" component={MyProfile} /> */}
                    <Route component={NotFound} />
                </Switch>
                <Footer />
                <ToastContainer theme="dark" />
            </Router>
        </>
    );
}

export default Routes;