import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";

const BrideHome = () => {

  return (
    <>
      <PageTitle title="उपवधू" />
      <section className="vh_dashboard vh_home">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/bride-photo">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/image.png" />
                <h2>Photo PDF</h2>
                <p>उपवधू फोटो</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/bride-text">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/pdf.png" />
                <h2>Text PDF</h2>
                <p>उपवधू माहिती</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/brides-excel">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/sheet.png" />
                <h2>Excel</h2>
                <p>उपवधू माहिती</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BrideHome;
