import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Form, Alert } from "react-bootstrap";
import ProfileService from "../services/profile.service";
import PageTitle from "../components/PageTitle/PageTitle";
import {
  convertToDevanagariTime,
  convertToDevanagariDate,
  convertDate,
} from "../helpers/utility";
import Urls from "../constants/urls";

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState({});
  const [message, setMessage] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const idJson = {
      'id': props.match.params.id
    }
    ProfileService.loadUserProfile(idJson)
        .then(response => {
            setUserProfile(response.data);
        })
        .catch(error => {
        });  
  }, []);

  const onSubmit = (data) => {
    data.id = props.match.params.id;
    ProfileService.updatePaymentStatus(data)
        .then(response => {
          setMessage({
            'success' : true,
            'message' : response.data.message,
          });
        })
        .catch(error => {
          setMessage(true);
          setMessage({
            'success' : false,
            'message' : error.response.data.message,
          });
        });  
  };

  return (
    <>
      <PageTitle title="माझे प्रोफाइल" />
      <section>
        <Container>
          <Row>
            <Col md={8} className="mb-3">
              <div className="info-container">
                <h4 className="section-title pb-3">
                  {userProfile.data != undefined ? userProfile.data.gender : ""} माहिती
                </h4>
                {userProfile.data != undefined ? (
                  <>
                  <div className="user-profile">
                    <div className="user-image">
                      <div className="user-no">&nbsp;</div>
                      {userProfile.data.photo != undefined ? (
                        <img
                          src={`${Urls.BACKEND_IMAGE_BASE_URL}/${userProfile.data.photo}`}
                          className="img-fluid"
                          alt="profile"
                        />
                      ) : (
                        ""
                      )}
                      <h2>
                        {userProfile.data.last_name} {userProfile.data.title}
                        {userProfile.data.first_name}
                      </h2>
                    </div>
                    <div className="user-details">
                      <div className="user-no">&nbsp;</div>
                      <div className="vh-flex user-name">
                        <p className="vh-flex-title">नाव</p>
                        <p className="vh-flex-value">
                          {userProfile.data.last_name} {userProfile.data.title}
                          {userProfile.data.first_name}{" "}
                          {userProfile.data.middle_name}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">जन्म ता./वेळ</p>
                        <p className="vh-flex-value">
                          {userProfile.data.dob != undefined
                            ? convertToDevanagariDate(userProfile.data.dob)
                            : ""}
                          |{userProfile.data.day_time}{" "}
                          {userProfile.data.birth_time != undefined
                            ? convertToDevanagariTime(
                                userProfile.data.birth_time
                              )
                            : ""}{" "}
                          मि.|जन्मगाव: {userProfile.data.birth_place}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">&nbsp;</p>
                        <p className="vh-flex-value">
                          उंची: {userProfile.data.height_foot}.
                          {userProfile.data.height_inch}" ।{" "}
                          {userProfile.data.varna} । रक्तगट:{" "}
                          {userProfile.data.blood_group}{" "}
                          {userProfile.data.divorced == "true"
                            ? "| घटस्फोटित"
                            : ""}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">शिक्षण</p>
                        <p className="vh-flex-value">
                        {userProfile.data.profession} {userProfile.data.education}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">नो./व्यवसाय</p>
                        <p className="vh-flex-value">
                          {userProfile.data.occupation}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">वार्षिक उत्पन्न:</p>
                        <p className="vh-flex-value">
                          {userProfile.data.annual_income}
                        </p>
                      </div>
                      
                      <div className="vh-flex">
                        <p className="vh-flex-title">अपेक्षा:</p>
                        <p className="vh-flex-value">
                          {userProfile.data.expectations}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">भाऊ विवाहित</p>
                        <p className="vh-flex-value">
                          {userProfile.data.married_brothers} &nbsp; अविवाहित:{" "}
                          {userProfile.data.unmarried_brothers} / बहीण विवाहित:{" "}
                          {userProfile.data.married_sisters} &nbsp; अविवाहित:
                          {userProfile.data.unmarried_sisters}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">वडिलांचे नाव</p>
                        <p className="vh-flex-value">
                          {userProfile.data.parent_first_name}{" "}
                          {userProfile.data.parent_middle_name}{" "}
                          {userProfile.data.parent_last_name}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">पत्ता</p>
                        <p className="vh-flex-value">
                          {userProfile.data.parent_address} मो.
                          {userProfile.data.parent_mobile}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">मामकूळ</p>
                        <p className="vh-flex-value">
                          {userProfile.data.mama_first_name}{" "}
                          {userProfile.data.mama_middle_name}{" "}
                          {userProfile.data.mama_last_name},{" "}
                          {userProfile.data.mama_address}, मो.
                          {userProfile.data.mama_mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="vh_post_details">
                    <h3>माहिती पुस्तिका खाली दिलेल्या पत्यावर पाठवली जाईल</h3>
                    <p>नाव: {userProfile.data.post_last_name}{" "}{userProfile.data.post_first_name}{" "}{userProfile.data.post_middle_name}<br/>पत्ता: {userProfile.data.post_address}<br/>{userProfile.data.post_district == "महाराष्ट्रा बाहेरील" ? '' : (userProfile.data.post_district + ' - ')}{userProfile.data.post_pincode}<br/> 
मोबाइल नं.: {userProfile.data.post_mobile}</p>
                    </div>
                    </>
                ) : (
                  <p className="text-danger">लोडींग...</p>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className="bg-white p-4">
                <h4 className="section-title pb-3">प्रोफाइलची माहिती</h4>
                {userProfile.data != undefined ? (
                  <div>
                    <p>
                      Username:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.username}
                      </span>
                    </p>
                    <p>
                      Password:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.mobile}
                      </span>
                    </p>
                    <p>
                      पेमेंट स्टेटस:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.payment_status}
                      </span>
                    </p>
                    <p>
                    ऑर्डर ID:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.razorpay_order_id}
                      </span>
                    </p>
                    <p>
                    पेमेंट ID:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.razorpay_payment_id}
                      </span>
                    </p>
                    <p>
                      नोंदणी दिनांक:{" "}
                      {userProfile.data.created_at != undefined
                        ? convertDate(userProfile.data.created_at)
                        : ""}
                    </p>
                    <p>
                      प्रोफाइल बदल:{" "}
                      {userProfile.data.updated_at != undefined
                        ? convertDate(userProfile.data.updated_at)
                        : ""}
                    </p>
                    <Link to={"/upload-image/"+userProfile.data.id} className="btn btn-danger w-100 mb-3">
                      फोटो बदला
                    </Link>
                    <Link to={"/edit-details/"+userProfile.data.id} className="btn btn-danger w-100 mb-3">
                      एडिट प्रोफाइल
                    </Link>
                  </div>
                
                ) : (
                  <p className="text-danger">लोडींग...</p>
                )}
              </div>
              
              <div className="bg-white p-4 mt-3">
                <h4 className="section-title pb-3" style={{'fontSize':'1.2rem'}}>अपडेट पेमेंट स्टेटस</h4>
              <div>
              {
                message.success != undefined ? (
                <Alert variant={message.success ? 'success' : 'error'}>
                  {message.message}
                </Alert>
                ) : ''
              }
              </div>
              {userProfile.data != undefined ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group
                    className="mb-3"
                    controlId="payment_status"
                  >
                    <Form.Select
                      defaultValue={userProfile.data.payment_status}
                      {...register("payment_status", {
                        required: true,
                      })}
                    >
                    <option value="Unpaid">Unpaid</option>
                      <option value="Paid">Paid</option>
                    </Form.Select>
                    <p className="vh-error">
                      {errors.payment_status?.type === "required" && "शीर्षक आवश्यक आहे"}
                    </p>
                  </Form.Group>

                <Button variant="warning" type="submit" className="w-100">
                अपडेट करा
                </Button>
              </Form>) : (
                  <p className="text-danger">लोडींग...</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    
      </>
  );
};

export default UserProfile;
