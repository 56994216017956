import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";
import TodaysRegistrations from "../components/TodaysRegistrations/TodaysRegistrations";
import { convertToDevanagari } from "../helpers/utility";
import ProfileService from "../services/profile.service";

const Dashboard = () => {
  const [dashboardCount, setDashboardCount] = useState({});

  useEffect(() => {
    ProfileService.getDashboardCount()
        .then(response => {
            setDashboardCount(response.data.data);
        })
        .catch(error => {
        });  
  }, []);

  return (
    <>
      <PageTitle title="डॅशबोर्ड" />
      <section className="vh_dashboard">
        <Container>
          <Row>
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/all-registrations">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/couple.png" />
                {dashboardCount != undefined && dashboardCount.all_count != undefined ? (<h2>{convertToDevanagari(dashboardCount.all_count)}</h2>): ''}
                <p>एकूण नोंदणी</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/bride-home">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/woman.png" />
                {dashboardCount != undefined && dashboardCount.all_brides != undefined ? (<h2>{convertToDevanagari(dashboardCount.all_brides)}</h2>): ''}
                <p>एकूण उपवधू</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="vh_card">
                <div className="vh_right">
                  <Link to="/groom-home">येथे पाहा</Link>
                </div>
                <img src="https://admin.lingayatyavatmal.com/assets/img/man.png" />
                {dashboardCount != undefined && dashboardCount.all_grooms != undefined ? (<h2>{convertToDevanagari(dashboardCount.all_grooms)}</h2>): ''}
                <p>एकूण उपवर</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3">
          <Row>
            <Col md={12}>
              <div className="vh_card">
                <h2 className="section-title" style={{'fontSize':'1.5rem'}}>आजच्या नोंदणी</h2>
                <TodaysRegistrations />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
