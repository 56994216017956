import actionTypes from './user.actionTypes';
import initialState from './user.initialState';

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.RESTART_AUTH_RESPONSE:
            return {
                ...state,
                isLoading: false,
                user: null,
                errorMessage: null
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: true,
                user: null,
                errorMessage: null
            };
        case actionTypes.REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: payload
            };
        case actionTypes.REGISTRATION_FAILURE:
            // console.log('registration failure payload: ', payload);
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: payload
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: payload
            };
        case actionTypes.LOGOUT_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            };
        default:
            return state;
    }
};

export default userReducer;