import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md={6}>
            <p className="text-center text-md-start mb-0">
              कॉपीराइट &copy; २०२४ वीरशैव हितसंवर्धक मंडळ, यवतमाळ
            </p>
          </Col>
          <Col md={6}>
            <p className="powered-by-text text-center text-md-end mb-0">
              Powered by{" "}
              <a
                href="https://www.linkedin.com/in/aniruddha-khartade/"
                title="Slash Technologies - Websites, App, Digital Marketing Agency in India"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Slash Technologies
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
