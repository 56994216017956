import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import PageTitle from "../components/PageTitle/PageTitle";

const Contact = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const [userInfo, setUserInfo] = useState();
  const onSubmit = (data) => {
    setUserInfo(data);
  };
  return (
    <>
      <PageTitle title="संपर्क" />
      <section>
        <Container>
          <Row>
            <Col md={6}>
              <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">दूरध्वनी क्र.</h4>
                <p>८८२९३८२९८३</p>
              </div>
              <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">ई-मेल</h4>
                <p>vhmy@gmail.com</p>
              </div>
              <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">पत्ता</h4>
                <p>वीरशैव हितसंवर्धक मंडळ, यवतमाळ</p>
              </div>
            </Col>
            <Col md={6}>
              <pre>{JSON.stringify(userInfo, undefined, 2)}</pre>
              <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <h2 className="form-title">संपर्क साधा</h2>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>
                    पूर्ण नाव <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="पूर्ण नाव टाईप करा"
                    {...register("name", {
                      required: true,
                    })}
                  />
                  <p className="vh-error">
                    {errors.name?.type === "required" && "पूर्ण नाव आवश्यक आहे"}
                  </p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>
                    ई-मेल <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="ई-मेल टाईप करा"
                    {...register("email", {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    })}
                  />
                  <p className="vh-error">
                    {errors.email?.type === "required" && "ई-मेल आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.email?.type === "pattern" &&
                      "कृपया वैध ई-मेल प्रविष्ट करा"}
                  </p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>
                    संदेश <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="आपला संदेश टाईप करा"
                    style={{ height: "100px" }}
                    {...register("message", {
                      required: true,
                    })}
                  />
                  <p className="vh-error">
                    {errors.message?.type === "required" && "संदेश आवश्यक आहे"}
                  </p>
                </Form.Group>

                <Button variant="danger" type="submit" className="w-100">
                  संदेश पाठवा
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
